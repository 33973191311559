import React from "react";
import profile from '../asset/profile.jpg';

const About = () => {
    return(
        <section id="about" className="py-20 bg-gray-800">
            <div className="container mx-auto text-center px-6" style={{borderTop: '2px solid white'}}>
                <h2 className="text-4xl font-bold mb-8 text-gray-800">
                    Tentang Saya
                </h2>
                <div className="flex flex-col items-center mb-12">
                    <img src={profile} alt="Profile picture" className="w-32 h-32 rounded-full object-cover mb-4 shadow-lg"/>
                    <p className="text-lg text-white mb-2">KiDi Intern</p>
                    <p className="text-xl text-white font-semibold">I’am an software  engineer intern in KIDI Telkom educourse</p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div className="bg-white p-6 rounded-lg hover:bg-black hover:text-white shadow-lg duraation-300">
                    <h3 className="text-2xl font-bold mb-4">ReactJS</h3>
                    <p className="mb-4">
                        ReactJS adalah library JavaScript 
                        popular yang dibuat oleh Facebook 
                        yang digunakan dalam proses 
                        pengembangan aplikasi mobile dan web
                    </p>
                    </div>

                    <div className="bg-white p-6 rounded-lg hover:bg-black hover:text-white shadow-lg duraation-300">
                    <h3 className="text-2xl font-bold mb-4">UI / UX</h3>
                    <p className="mb-4">
                        berkaitan dengan tampilan visual produk
                        sementara UX Design berfokus pada
                        UI/UX adalah disiplin desain yang fokus
                        pada cara pengguna berinteraksi dengan 
                        suatu produk atau layanan. UI Design
                        pengalaman pengguna secara keseluruhan
                    </p>
                    </div>

                    <div className="bg-white p-6 rounded-lg hover:bg-black hover:text-white shadow-lg duraation-300">
                    <h3 className="text-2xl font-bold mb-4">NodeJS</h3>
                    <p className="mb-4">
                        Node.js® adalah lingkungan runtime
                        JavaScript gratis dan sumber terbuka 
                        yang lintas platform, yang memungkinkan
                        pengembang membuat server, aplikasi
                        web, alat baris perintah, dan skrip.
                    </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;