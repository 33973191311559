import React from "react";

const Contact = () => {
    return(
        <section id="contact" className="py-20 bg-gray-800" style={{
            borderTop: '2px solid white'
        }}>
            <div className="container mx-auto px-6 text-center">
                <h2 className="text-4xl font-bold mb-0 text-white">Contact Me</h2>
                <p className="text-md text-white">
                    Feel free to leave a message 
                </p>
                <p className="text-xl mb-4 font-semibold text-white">
                    Naufallintang16@gmail.com
                </p>
                <a href="mailto:Naufallintang16@gmail.com" className="bg-black text-white font-semibold py-2 px-3 rounded-lg">Send Mail</a>
            </div> 
        </section>
    )
}

export default Contact;