import React from "react";

const Footer = () => {
    return(
        <footer className="bg-black text-white py-6">
            <div className="container mx-auto text-center">
                <p className="text-sm mb-0">&copy; {new Date().getFullYear()} Naufal Lintang. all right reserved</p>
            </div>
        </footer>
    )
}

export default Footer;