import React from "react";
import project1 from '../asset/PERPUSTAKAAN.png';
import project2 from '../asset/music.png';
import project3 from '../asset/weather.png';

const Project = () => {
    return(
        <section id="projects" className="py-20 bg-gray-800" style={{
            borderTop: '2px solid white'
        }}>
            <div className="container mx-auto text-center">
                <h2 className="text-2xl font-bold mb-8 text-white"> My Project </h2>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
                    <div className="bg-white p-4 rounded-lg relative">
                        <img src={project1} alt="samuel wattimena" className="w-full h-48 object-cover mb-4 rounded-md opacity-80"/>
                        <h3 className="text-2xl font-bold mb-2">Prototype Perpustakaan</h3>
                    </div>

                    <div className="bg-white p-4 rounded-lg relative">
                        <img src={project2} alt="samuel wattimena" className="w-full h-48 object-cover mb-4 rounded-md opacity-80"/>
                        <h3 className="text-2xl font-bold mb-2">Music login page APP</h3>
                    </div>

                    <div className="bg-white p-4 rounded-lg relative">
                        <img src={project3} alt="samuel wattimena" className="w-full h-48 object-cover mb-4 rounded-md opacity-80"/>
                        <h3 className="text-2xl font-bold mb-2">Weather APP</h3>
                    </div>

                </div>

            </div>

        </section>
    )
}

export default Project;